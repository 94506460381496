import { Injectable } from '@angular/core';
import { ApiService, Image, ImageByPerson, Pack } from './api.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataCacheService {
  private imagesCache$ = new BehaviorSubject<Image[] | null>(null);
  private packsCache$ = new BehaviorSubject<Pack[] | null>(null);
  private imagesByPersonCache$ = new BehaviorSubject<ImageByPerson[] | null>(null);

  constructor(private apiService: ApiService) {}

  // Load images and cache them
  loadImages(): Observable<Image[]> {
    return this.apiService.getImages().pipe(
      tap(images => this.imagesCache$.next(images))
    );
  }

  // Load images by person and cache them
  loadImagesByPerson(params?: string[]): Observable<ImageByPerson[]> {
    return this.apiService.getImagesByPerson(params).pipe(
      tap(imagesByPerson => this.imagesByPersonCache$.next(imagesByPerson))
    );
  }

  // Load packs and cache them
  loadPacks(): Observable<Pack[]> {
    return this.apiService.getPacks().pipe(
      tap(packs => this.packsCache$.next(packs))
    );
  }

  // Get cached images
  getCachedImages(): Observable<Image[] | null> {
    return this.imagesCache$.asObservable();
  }

  // Get cached images by person
  getCachedImagesByPerson(): Observable<ImageByPerson[] | null> {
    return this.imagesByPersonCache$.asObservable();
  }

  // Get cached packs
  getCachedPacks(): Observable<Pack[] | null> {
    return this.packsCache$.asObservable();
  }

  // Clear caches if needed
  clearCache(): void {
    this.imagesCache$.next(null);
    this.packsCache$.next(null);
    this.imagesByPersonCache$.next(null);
  }
}
