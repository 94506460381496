import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private toastService: ToastrService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        
        let errorMsg = 'Something went wrong';

        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = `Error Code: ${error.status}\nMessage: ${error.message}`;
          // Если ошибка 404, выполняем редирект на страницу /login
          if (error.status === 401 || error.status === 422 || (error.status === undefined)) {
            this.router.navigate(['/login']);
          }
        }

        if (error.status !== 401) {
          console.error('HTTP Error:', error, errorMsg);
          this.toastService.error(errorMsg);
        }



        return throwError(() => error);
      })
    );
  }
}
