import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, take } from 'rxjs';
import { ApiService } from './api.service';

export enum UserStatus {
  admin,
  client
}

export interface UserInfo {
  status: UserStatus,
  name: string;
  email: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private clientSubject: BehaviorSubject<UserInfo | null> = new BehaviorSubject<UserInfo | null>(null);
  private userInfo!: UserInfo;

  constructor(private apiService: ApiService) {
    this.initializeClientStatus();
  }

  private initializeClientStatus(): void {
    if (!this.userInfo) {
      this._fetchUserInfo().pipe(take(1)).subscribe();
    }
  }

  public updateUserInfo(): Observable<boolean> {
    return this._fetchUserInfo();
  }

  private _fetchUserInfo(): Observable<boolean> {
    return this.apiService.getAuth().pipe(
      take(1),
      map(data => {
        this.userInfo = {
          name: data.name,
          email: data.email,
          status: data.name === 'backOffice' ? UserStatus.admin : UserStatus.client
        };
        this.clientSubject.next(this.userInfo);
        return true;
      }),
      catchError(error => {
        console.error('Ошибка при получении данных пользователя', error);
        this.clientSubject.next(null);
        return of(false);
      })
    );
  }

  public getUserInfo(): Observable<UserInfo | null> {
    return this.clientSubject.asObservable();
  }

  get isClient(): boolean | null {
    if (this.userInfo === null) {
      return null;
    }
    return this.userInfo?.status === UserStatus.client;
  }

  // Метод для очистки данных пользователя при логауте
  public clearUserInfo(): void {
    this.userInfo = null!;
    this.clientSubject.next(null);
  }
}
