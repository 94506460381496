import { UserService, UserStatus } from './shared/services/user.service';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { DataCacheService } from './shared/services/data-cache.service';
import { forkJoin, take } from 'rxjs';
import { AuthGuard } from './shared/guards/auth.guard';
import { ThemeService } from './shared/services/theme.service';
import { AuthService } from './shared/services/auth.service'; // Импортируем AuthService

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterLink, RouterOutlet, CommonModule],
  providers: [AuthGuard],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  constructor(
    private dataCacheService: DataCacheService,
    private themeService: ThemeService,
    public userService: UserService,
    private authService: AuthService // Инжектим AuthService
  ) { }

  userStatus = UserStatus;

  ngOnInit(): void {
    console.log('INIT');
    this.loadInitialData();
    this.themeService.detectTheme();
  }

  private loadInitialData(): void {
    forkJoin({
      imagesByPerson: this.dataCacheService.loadImagesByPerson(),
      packs: this.dataCacheService.loadPacks()
    }).pipe(
      take(1)
    ).subscribe();
  }

  // Метод для логаута
  logout(): void {
    this.authService.logout();  // Вызываем логаут из AuthService
  }
}
