import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, delay, map, Observable, tap } from 'rxjs';
import { FileSaverService } from 'ngx-filesaver'; // Импортируем FileSaverService
import { environment } from '../../../environments/environment';

export enum PackStatus {
  new = 'NEW',
  inProgress = 'INPROGRESS',
  ready = 'READY',
  accepted = 'ACCEPTED',
  declined = 'DECLINED',
  sentToPartners = 'SENT',
  approvedByPartners = 'APPROVED',
  rejectedByPartners = 'REJECTED'
}

export enum ImageStatus {
  accepted = 'ACCEPTED',
  declined = 'DECLINED'
}

// interfaces.ts
export interface Image {
  id: number;
  name: string;
  url: string;
  is_face: boolean;
  person_id: number;
  tags: string[];
}

export enum DressedImageStatus {
  NEW = 'NEW',
  NEW_EXTRA_GENERATED = 'NEW_EXTRA_GENERATED',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export interface DressedImage {
  pack_id: number;
  res_image_id: number;
  res_small_image_url: string;
  res_tiny_image_url: string;
  res_image_url: string;
  like_flag: boolean | null;
  path: string;
  cropped?: boolean;
  cropOriginal?: string;
  status: DressedImageStatus;
}

export interface PackFaceImage {
  face_image_id: number;
  face_image_url: string;
  pack_id: number;
}

export interface ImageWithTags {
  id: number;
  url: string;
  is_face: boolean;
  person_id: string;
}

export interface ImageByPerson {
  person_id: number;
  images: Image[];
}

export interface FaceImage {
  name: string;
  image64: string;
}

export enum ExtraGenerateTaskStatus {
  COMLETED = 'COMLETED',
  ERROR = 'ERROR',
  INPROGRESS = 'INPROGRESS',
}

export interface ExtraGenerateTask {
  count: number;
  id: number;
  negative_prompt: string;
  pack_id: number;
  prompt: string;
  status: ExtraGenerateTaskStatus
}
export interface Pack {
  id?: number;
  name: string;
  description?: string;
  imageUrl?: string;
  generatedImages?: string[];
  images?: DressedImage[];
  dressed_images?: DressedImage[];
  generationInProgress?: boolean;
  status: PackStatus
  showImageAccepted?: boolean;
  showImageNotMarked?: boolean;
  showImageDeclined?: boolean;
  showExtraGenerated?: boolean;
  statusLoading?: PackStatus | 'delete' | null;
  comment: string;
  isLoading?: boolean;
  isAddGenerating?: boolean;
  prompt?: string;
  total_images: number;
  approved_images: number;
  isUpdatingComment: boolean;
  extra_generation_tasks: ExtraGenerateTask[];
  user: {
    email: string;
    id: number;
    name: string;
    role: string;
  };
  moodboard?: string;
}

export interface NewPack {
  name: string;
  description: string;
  face_images: FaceImage[];
}

export interface ImageInPack {
  image_id: number;
  image_url: string;
  pack_id: number;
}

export enum Reaction {
  like,
  dislike
}
export interface Tag {
  image_id: number;
  tag: string;
  val: number;
}

export interface Result {
  result: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // private baseUrl = 'https://api.aitesting.app';
  private baseUrl = environment.apiUrl;;

  constructor(
    private http: HttpClient,
    private fileSaverService: FileSaverService
  ) { }

  getImages(): Observable<Image[]> {
    return this.http.get<Image[]>(`${this.baseUrl}/images`);
  }

  getImagesByPerson(params?: string[]): Observable<ImageByPerson[]> {
    console.log('params', params);
    const paramsArray = params && Object.keys(params) || [];
    return this.http.get<ImageByPerson[]>(`${this.baseUrl}/images?group_by_person_id=true&${paramsArray?.join('&')}`);
  }

  postImage(image: { name: string; path: string; person_id: number }): Observable<Image> {
    return this.http.post<Image>(`${this.baseUrl}/images`, image);
  }

  // DELETE /images/{image_id}
  deleteImage(image_id: number): Observable<Result> {
    return this.http.delete<Result>(`${this.baseUrl}/images/${image_id}`);
  }

  // GET /packs
  getPacks(filter?: string): Observable<Pack[]> {
    return this.http.get<Pack[]>(`${this.baseUrl}/packs?search=${filter || ''}`);
  }

  // POST /packs
  postPack(pack: NewPack): Observable<Pack> {
    return this.http.post<Pack>(`${this.baseUrl}/packs`, pack);
  }

  // GET /packs/{pack_id}/images
  getPackImages(pack_id: number): Observable<ImageInPack[]> {
    return this.http.get<ImageInPack[]>(`${this.baseUrl}/packs/${pack_id}/images?not_used=true`);
  }

  // POST /packs/{pack_id}/images
  postPackImage(packId: number, imageId: number): Observable<Image> {
    return this.http.post<Image>(`${this.baseUrl}/packs/${packId}/images`, { image_id: imageId });
  }

  // POST /packs/{pack_id}/images
  postAllPersonPackImage(packId: number, personId: number): Observable<Image> {
    return this.http.post<Image>(`${this.baseUrl}/packs/${packId}/images`, { person_id: personId });
  }

  // DELETE /tags/{image_id}
  deleteTags(image_id: number, tags: Tag): Observable<Result> {
    return this.http.request<Result>('delete', `${this.baseUrl}/tags/${image_id}`, { body: tags });
  }

  // POST /tags/{image_id}
  postTags(image_id: number, tags: Tag): Observable<Result> {
    return this.http.post<Result>(`${this.baseUrl}/tags/${image_id}`, tags);
  }

  // POST /packs/{pack_id}/generate
  generatePhotosInTaskForPack(pack_id: number): Observable<Result> {
    return this.http.post<Result>(`${this.baseUrl}/packs/${pack_id}/generate`, {});
  }

  // GET /tags
  getTags(): Observable<Tag[]> {
    return this.http.get<Tag[]>(`${this.baseUrl}/tags`);
  }

  getDressedPhotosForPack(packId: number): Observable<DressedImage[]> {
    return this.http.get<DressedImage[]>(`${this.baseUrl}/packs/${packId}/dressimages`);
  }

  getFacePhotosForPack(packId: number): Observable<PackFaceImage[]> {
    return this.http.get<PackFaceImage[]>(`${this.baseUrl}/packs/${packId}/faceimages`);
  }

  deletePack(packId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/packs/${packId}`);
  }

  deletePackImage(packId: number, imageId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/packs/${packId}/images/${imageId}`);
  }

  markPersonAsUsed(personId: number) {
    return this.http.put(`${this.baseUrl}/persons/${personId}`, { is_dressed: true });
  }

  reactPhoto(packId: number, photoId: number, reaction: Reaction | null) {
    return this.http.put(`${this.baseUrl}/images/${photoId}/like`, { flag: reaction === Reaction.like ? true : Reaction.dislike ? false : null });
  }

  setPackStatus(packId: number, status: PackStatus) {
    return this.http.put(`${this.baseUrl}/packs/${packId}/status`, { packId: packId, status });
  }

  // api.service.ts
  updatePackComment(packId: number, comment: string): Observable<any> {
    return this.http.put(`${this.baseUrl}/packs/${packId}/comment`, { comment });
  }


  downloadDressedPhotosForPack(packId: number): void {
    this.http.get(`${this.baseUrl}/packs/${packId}/dressimages/download`, {
      responseType: 'blob' // Указываем, что ожидаем бинарные данные
    }).subscribe((response: Blob) => {
      const filename = `pack_${packId}_images.zip`;
      this.fileSaverService.save(response, filename); // Сохраняем файл с помощью ngx-filesaver
    });
  }

  uploadCroppedImage(packId: number, imageId: number, image: string): Observable<any> {
    const cleanedImage = image.split(',')[1]; // Убираем префикс base64
    return this.http.post(`${this.baseUrl}/crop_image`, { image_id: imageId, image: cleanedImage, pack_id: packId });
  }


  generateNewPhotosInPack(packId: number, prompt: string): Observable<ExtraGenerateTask> {
    return this.http.post<ExtraGenerateTask>(`${this.baseUrl}/extra_generate_task`, {
      pack_id: packId, prompt, steps: 30, count: 3
    });
  }

  getExtrageneratedPackStatus(packId: number,): Observable<ExtraGenerateTask[]> {
    return this.http.get<ExtraGenerateTask[]>(`${this.baseUrl}/pack/${packId}/extra_generation_tasks`);
  }

  // Add the login method
  login(email: string, password: string): Observable<any> {
    console.log('LOGIN');
    return this.http.post(`${this.baseUrl}/login`, { email, password }).pipe(
      tap(res => console.log('login res', res)),
      catchError(err => {
        console.error('@@@@@@@$$', err);
        throw Error('AAKJHFKUYALJHAGLJKAGLA');
      })
    );
  }

  getAuth(): Observable<any> {
    return this.http.get(`${this.baseUrl}/auth`);
  }

  // Method to check if user is authenticated
  isAuthenticated(): boolean {
    const token = localStorage.getItem('access_token');
    return !!token;
  }

  // api.service.ts

  getUserBalance(): Observable<number> {
    return this.http.get<{balance: number}>(`${this.baseUrl}/transactions/balance`).pipe(map(res => res.balance));
  }

  enrichPrompt(prompt: string): Observable<string> {
    return this.http.post<{result: string}>(`${this.baseUrl}/enrich_prompt`, {prompt}).pipe(map(res => res.result));
  }

}
