<div class="d-flex" id="wrapper">
    <!-- Левое боковое меню -->
    <div class="bg-light border-right fixed-sidebar" id="sidebar-wrapper">
        <div class="list-group list-group-flush">
            <!-- <a routerLink="/images" class="list-group-item list-group-item-action bg-light">Raw Images</a>
            <a routerLink="/packs" class="list-group-item list-group-item-action bg-light">Packs</a> -->
            <a routerLink="/packs-manager" class="list-group-item list-group-item-action bg-light">Packs Manager</a>
        </div>

        <div class="container mt-4" *ngIf="(userService.getUserInfo() | async) as userInfo">
            <h6>User Information</h6>
            <div class="">
                <div class="d-flex flex-column">
                    <div class="me-3">
                        <strong>Role:</strong> {{ userInfo.status === userStatus.admin ? 'Admin' : 'Client' }}
                    </div>
                    <div class="me-3">
                        <strong>Name:</strong> {{ userInfo.name }}
                    </div>
                    <div class="me-3">
                        <strong>Email:</strong><br> {{ userInfo.email }}
                    </div>
                </div>
            </div>
            <!-- Кнопка Logout -->
            <button class="btn btn-danger btn-sm mt-3" (click)="logout()">Logout</button>
        </div>
    </div>

    <main class="main flex-grow-1">
        <router-outlet></router-outlet>
    </main>
</div>
