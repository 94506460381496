import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor() {
    this.detectTheme();
  }

  detectTheme() {
    const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    if (isDarkMode) {
      this.setDarkTheme();
    } else {
      this.setLightTheme();
    }

    // Слушаем изменение темы
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
      if (event.matches) {
        this.setDarkTheme();
      } else {
        this.setLightTheme();
      }
    });
  }

  setDarkTheme() {
    document.body.setAttribute('data-bs-theme', 'dark');
  }

  setLightTheme() {
    document.body.setAttribute('data-bs-theme', 'light');
  }
}
