import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  // Логин
  login(email: string, password: string): Observable<boolean> {
    return this.apiService.login(email, password).pipe(
      tap(res => {
        const token = res.access_token;
        localStorage.setItem('access_token', token);
      }),
      switchMap(() => this.userService.updateUserInfo()),
      map(() => true),
      catchError(err => {
        this.toastr.error(err?.error?.reason || 'Login failed', 'Error');
        return of(false);
      })
    );
  }

  // Логаут
  logout(): void {
    localStorage.removeItem('access_token');
    this.userService.clearUserInfo(); // Очистка данных пользователя
    this.router.navigate(['/login']);
    this.toastr.success('Logged out successfully');
  }

  // Проверка аутентификации
  isAuthenticated(): boolean {
    return !!localStorage.getItem('access_token');
  }
}
