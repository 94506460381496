import { Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

export const routes: Routes = [
    { path: '', redirectTo: 'packs-manager', pathMatch: 'full' },
    { path: 'images', loadComponent: () => import('./components/images/images.component').then(m => m.ImagesComponent) },
    // { path: 'packs', loadComponent: () => import('./components/packs/packs.component').then(m => m.PacksComponent) },
    { path: 'packs-manager', loadComponent: () => import('./components/packs-manager/packs-manager.component').then(m => m.PacksManagerComponent), canActivate: [AuthGuard] },
    { path: 'login', loadComponent: () => import('./components/login/login.component').then(m => m.LoginComponent) }
];
