import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { routes } from './app.routes';
import { ErrorInterceptor } from './shared/interceptors/error-interceptor';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // Импортируем модуль анимаций
import { AuthInterceptor } from './shared/interceptors/auth-interceptor';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';



export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),    // Подключение маршрутов
    provideHttpClient(
      withInterceptorsFromDi()
    ),
    importProvidersFrom(
      BrowserAnimationsModule, // Добавляем BrowserAnimationsModule
      ToastrModule.forRoot(),
      NgxBootstrapIconsModule.pick(allIcons) // Подключаем все иконки
    ),
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ]
};
